.card-all {
    width: 420px;
    background: linear-gradient(9deg, rgba(255, 255, 255, 1) 0%, rgba(20, 108, 148, 0.5) 100%);
    padding: 10px 30px;
    text-align: center;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border-radius: 10px;
    margin: 0 auto;
}

.card-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.pyschoCardProfileImage {
    background-color: red;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    z-index: 2;
    overflow: hidden;
}

.pyschoCardProfileImage img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.preliminary-interview {
    width: 140px;
    height: 35px;
    background-color: #FFFFFF;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 107px;
    right: 222px;
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    color: #146C94;
}

.online-info {
    width: 140px;
    height: 35px;
    background-color: #FFFFFF;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    left: 235px;
    position: absolute;
    top: 40px;
}

.online-info p {
    font-size: 14px;
    color: #146C94;
    font-weight: 500;
}

.psycho-info h1 {
    font-size: 32px;
    color: black;
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 10px;
}

.psycho-info h4 {
    font-size: 24px;
    margin-bottom: 10px;
}

.psycho-info {
    display: flex;
    flex-direction: column;
}

.client-seans {
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.client-seans p {
    background-color: #BFCDDF;
    border-radius: 40px;
    padding: 5px 15px;
    font-size: 15px;
}

.client-seans p:first-child {
    margin-right: 20px !important;
}

.price-flag {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    align-items: center;
}

.price-flag .tr {
    margin-right: 10px;
}

.price-flag .de {
    margin-right: 10px;
}

.price-flag .price {
    display: flex;
    margin-left: 15px;
}

.price .price1 {
    background-color: #146C94;
    border-radius: 10px 0 0 0;
    padding: 4px 10px;
    color: white;
    margin-right: 4px;
    font-size: 15px;
}

.flag {
    display: flex;
}

.flag img {
    font-size: 25px;
}

.price .price2 {
    background-color: #146C94;
    border-radius: 0 0 10px 0;
    padding: 4px 10px;
    color: white;
    font-size: 15px;
}

.terapist {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.terapist p {
    width: 200px;
    background-color: #BFCDDF;
    border-radius: 40px;
    padding: 5px 10px;
    font-size: 15px;
}

.psycho-footer p {
    font-size: 16px;
    margin-top: 30px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}

.psycho-footer .readmore {
    color: blue;
    margin-left: 10px;
}

.psycho-footer-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.icon {
    font-size: 2px;
}

.psycho-right-button {
    fill: #146C94;
    stroke-width: 1px;
    stroke: #000;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-buttons-py {
    width: 35%;
    display: flex;
    justify-content: space-between;
}

.psycho-footer-buttons {
    margin-top: 16.11px;
}

.online {
    width: 10px;
    height: 10.072px;
    flex-shrink: 0;
    background: #31F600;
    border-radius: 100%;
    margin-left: 10px;
}

.button-expert {
    border-radius: 70px;
    background: linear-gradient(180deg, rgba(20, 108, 148, 0.8) 50%, rgba(20, 108, 148, 0) 172.92%);
    padding: 16px 37px;
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.client-seans {
    font-family: 'Montserrat', sans-serif;
}

.client-seans p {
    margin-bottom: 0.5rem;
}

.client-seans p:first-child {
    position: relative;
}

.slick-arrow .slick-next {
    display: none !important;
}


/* .client-seans p:first-child::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 42%;
    width: 2px;
    background-color: black;
} */

.psychoCardIcon svg {
    width: 17px;
    height: 17px;
}

@media only screen and (max-width:1280px) {
    .card-all {
        width: 100%;
        height: auto;
    }

    .preliminary-interview,
    .online-info {
        display: none;
    }
}