.login-form {
    margin-top: 100px;
    flex-direction: columns;
    .continue-with {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
            color: #000;
            text-align: center;
            font-family: Montserrat;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration: none;
            margin: 0;
            margin-bottom: 20px;
        }
        .face-log {
            border-radius: 50px;
            border: 1px solid #146c94;
            background: #fff;
            color: #000;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 450px;
        }
        .facebook {
            svg {
                fill: blue !important;
            }
            span {
                color: #000;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 15px;
                white-space: nowrap;
            }
        }
        .google {
            svg {}
            span {
                color: #000;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 15px;
                white-space: nowrap;
            }
        }
    }
    .or {
        .border-l {}
    }
    .user-area {
        flex-direction: column;
        display: flex;
        label {
            color: #000;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 20px;
            margin-bottom: 5px;
        }
        input {
            border-radius: 50px;
            border: 1px solid #146c94;
            background: #fff;
            width: 450px;
            height: 50px;
            flex-shrink: 0;
        }
        .user {
            display: flex;
            flex-direction: column;
            input {
                padding: 20px;
            }
        }
        .pass {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            input {
                padding: 20px;
            }
        }
    }
    .right {
        .active {
            background: #146c94;
            padding: 10px 30px;
            text-decoration: none;
            color: #fff;
            text-align: center;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .nt-active {
            color: #146c94;
            text-align: center;
            font-family: Montserrat;
            font-size: 28px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
        }
        .st-active {
            color: #146c94;
            text-align: center;
            font-family: Montserrat;
            font-size: 28px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            margin-left: 45px;
        }
    }
}

.login-form-line {
    width: 52%;
    margin: 35px auto;
    position: relative;
}

.login-form-line::before,
.login-form-line::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background-color: #000;
}

.login-form-line::before {
    left: 0;
}

.login-form-line::after {
    right: 0;
}

.login-form-line span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;
    background-color: #fff;
    font-weight: bold;
}

