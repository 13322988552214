.make-app-one {
    .profile-top {
        padding-top: 120px;

        .profile-img {
            margin: 0 auto;
            width: 165px;
            height: 165px;
            border-radius: 100%;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .profile-info {
            h1 {
                color: #000;
                font-family: Montserrat;
                font-size: 42px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                text-decoration: none;
                text-align: center;
                margin-top: 30px;
                margin-bottom: 0;

                span {
                    color: #000;
                    font-family: Montserrat;
                    font-size: 42px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    text-decoration: none;
                }
            }

            >span {
                color: #000;
                font-family: Montserrat;
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: block;
                text-align: center;
            }
        }
    }

    .input-card {
        margin-bottom: 100px;
        margin-top: 67px;
        flex-shrink: 0;
        border-radius: 10px;
        background: linear-gradient(180deg, rgba(20, 108, 148, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%);
        box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.25);
        padding: 54px 100px;

        h1 {
            color: #000;
            text-align: center;
            font-family: Montserrat;
            font-size: 42px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-decoration: none;
            margin-top: 0;
        }

        .input-area {
            border-radius: 50px;
            background: rgba(255, 255, 255, 0.00);
            box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.25);
            height: 100px;
            overflow: hidden;

            input {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                color: #7E7E7E;
                font-family: Montserrat;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                outline: 0;
            }

            .first-item {
                border-right: 2px solid #D9D9D9;
                padding-left: 30px;
            }
        }

        .button {
            display: flex;
            justify-content: center;
            margin-top: 60px;

            button {
                padding: 20px 0;
                width: 400px;
                border-radius: 50px;
                background: rgba(255, 255, 255, 0.00);
                box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.t-upper {
    text-transform: uppercase;
}

.h-100 {
    height: 100%;
}
