.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-list {
    list-style: none;
    display: flex;
}

.pagination-list li {
    margin: 0 5px;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.pagination-list li.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}