.client-note {
    width: 600px;
    height: 300px;
    background: linear-gradient(9deg, rgba(255, 255, 255, 1) 0%, rgba(20, 108, 148, 0.5) 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 0px 30px;
    margin: 20px;
}

.card-comment-info {
    white-space: normal;
}