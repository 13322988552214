.DateCard {
    display: flex;
    width: 600px !important;
    height: 220px;
    background: linear-gradient(rgba(28, 111, 150, 0.103) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 40px;
    margin: 20px;
}

.card-profil-img-2 {
    height: 100px;
    width: 100px;
}

.left-section {
    display: flex !important;
    justify-content: space-between !important;
}

.right-section {
    border-left: 3px solid rgba(0, 0, 0, 0.089);
    justify-content: left;
}

.image-image {
    background-color: aqua;
    border-radius: 50%;
    height: 150px;
    width: 200px;
}

.card-profil-info ul li {
    margin-bottom: 5px;
}

.userPanelDateCard2 {
    display: block !important;
    height: 220px;
    border-radius: 15px;
    background: linear-gradient(rgba(28, 111, 150, 0.219) 0%, rgba(255, 255, 255, 1) 100%);
    margin: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 50px;
}

.DateCard2 {
    display: flex;
    height: 220px;
    background: linear-gradient(rgba(28, 111, 150, 0.103) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 40px;
    margin: 20px;
}

.left-section ul li {
    margin-bottom: 10px;
}