section {
    margin: 0;
    margin-top: 50px;
    margin-bottom: 50px;
}

.homepage-first-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.homepage-first-sectiont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.homepage-first-section .text-h-first {
    font-size: 40px;
    color: #000;
    font-weight: 800 !important;
}

.homepage-first-section .text-h-second {
    font-size: 50px;
}

.homepage-first-section .text-homepage {
    text-align: left;
}

.text-image-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-left: 100px;
    padding-right: 40px;

}



.text-h-first {}

.text-homepage {}

.coming-soon-image-homepage {
    justify-content: center;
    width: 100%;
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
}

.coming-soon-image-homepage img {
    max-width: 100%;
    width: 430px;
}

.coming-soon-info {
    text-align: center;
}

.metaverse-title {

    font-weight: 700;
    text-decoration: none;
    text-align: center;
    border-top: 2px solid #146C94 !important;
    padding: 20px;
    margin-top: 50px;
}

.coming-soon-info p {
    text-align: center;
}

.text-homepage {
    text-align: center;
}

.metaverse-title {
    text-decoration: none;
    font-weight: 700;
}