.search-bar {
    display: flex;
    align-items: center;
    width: 600px;
    margin: 0 auto;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 30px;
}

.search-bar input[type="text"] {
    flex: 1;
    padding: 5px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    outline: none;
}

.search-bar button {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    outline: none;
}

.search-bar button:hover {
    background-color: #449af7;
}

.search-bar button:active {
    background-color: #004399;
}

@media only screen and (max-width:1280px) {
    .search-bar {
        width: 300px;
        margin: 10px;
    }
}