.login-form {
    margin: 100px 0;
    .continue-with {
        h1 {
            color: #000;
            text-align: center;
            font-family: Montserrat;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration: none;
            margin: 0;
            margin-bottom: 20px;
        }
        .face-log {
            border-radius: 50px;
            border: 1px solid #146c94;
            background: #fff;
            color: #000;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
        }
        .facebook {
            svg {
                fill: blue !important;
            }
            span {
                color: #000;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 15px;
                white-space: nowrap;
            }
        }
        .google {
            span {
                color: #000;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 15px;
                white-space: nowrap;
            }
        }
    }
    .user-area {
        flex-direction: column;
        display: flex;
        label {
            color: #000;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 20px;
        }
        input {
            border-radius: 50px;
            border: 1px solid #146c94;
            background: #fff;
            width: 100%;
            height: 50px;
            flex-shrink: 0;
        }
        .name-surname {
            display: flex;
        }
        .user-nd input {
            padding: 20px;
            display: flex;
            justify-content: space-between;
        }
        .user {
            display: flex;
            flex-direction: column;
            margin: 5px;
            input {
                padding: 20px;
            }
        }
        .pass {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            input {
                padding: 20px;
            }
        }
    }
    .right {
        .active {
            background: #146c94;
            padding: 10px 30px;
            text-decoration: none;
            color: #fff;
            text-align: center;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .nt-active {
            color: #146c94;
            text-align: center;
            font-family: Montserrat;
            font-size: 28px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .grid {
        .anopia-img {
            display: none;
        }
        .continue-with h1 {
            font-size: 22pxz;
        }
        .continue-with {
            display: flex;
            justify-content: center;
            max-width: 100%;
            justify-content: center;
            align-items: center;
            .facebook button {
                width: 250px;
            }
            .google button {
                width: 250px;
            }
            .user-area {
                .user input {
                    width: 250px;
                }
                .user-nd input {
                    width: 250px;
                }
                .name-surname {
                    flex-direction: column;
                }
                .doc-down input {
                    width: 250px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .user-nd input {
        display: flex;
        justify-content: space-between;
        width: 100% !important;
    }
    .user-area {
        margin-top: 50px;
    }
}