.card-comment {
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(20, 108, 148, 0.50) 0%, rgba(255, 255, 255, 0.00) 62.81%);
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    
    padding: 20px 20px;
}

.star {
    color: yellow !important;
}


.card-comment-star {
    display: flex;
}

.card-comment-info {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
}