@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap');
body {
    font-family: 'Montserrat', sans-serif;
}

.montserrat-b {
    font-weight: 700 !important;
}

.montserrat-s {
    font-weight: 600 !important;
}

.montserrat-m {
    font-weight: 500 !important;
}

.montserrat-r {
    font-weight: 400 !important;
}

.montserrat-eb {
    font-weight: 800 !important;
}

section {
    margin: 50px auto !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.slick-prev {
    background: #fff !important;
    color: #fff !important;
}

.slick-prev::before {
    color: black !important
}

header {
    background-color: #fff;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .15), 0 5px 10px rgba(0, 0, 0, .05);
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    section {
        padding: 0 20px;
    }
}