.userPanelDateCard {
    display: block !important;
    width: 400px !important;
    height: 400px !important;
    border-radius: 15px;
    background: linear-gradient(rgba(28, 111, 150, 0.219) 0%, rgba(255, 255, 255, 1) 100%);
    margin: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 50px;
}

.card-profil-img {
    height: 100px;
    width: 100px;
}

.dateCardImage {
    height: 150px !important;
    width: 150px !important;
    margin-bottom: 30px;
}

.left-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-text {
    background-color: rgba(28, 111, 150, 0.219);
    background: linear-gradient(rgba(28, 111, 150, 0.664) 100%, rgba(255, 255, 255, 1) 0%);
    border-radius: 20px;
    color: white;
    padding: 10px;
    margin-bottom: 20px;
    width: 200px;
}

.right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 40px;
}

.right-section ul {
    margin-top: 10px;
}

.right-section ul li {
    margin-bottom: 10px;
}

.top-section {
    display: flex;
    justify-content: center;
}

.down-section {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding: 0px;
}

.dateCardIcon svg {
    width: 17px;
}

@media only screen and (max-width:768px) {
    .userPanelDateCard {
        width: 280px !important;
        height: 320px !important;
        padding: 20px;
    }

    .card-profil-img {
        height: 80px;
        width: 80px;
    }

    .button-text {
        background-color: rgba(28, 111, 150, 0.219);
        background: linear-gradient(rgba(28, 111, 150, 0.664) 100%, rgba(255, 255, 255, 1) 0%);
        border-radius: 20px;
        color: white;
        padding: 10px;
        width: 150px;
    }

    .right-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px;
    }

    .down-section {
        display: flex;
        justify-content: space-between !important;
        margin-top: 30px;
        padding: 0px;
    }

    .right-section {
        padding-left: 5px;
    }

    .down-section {
        padding-bottom: 10px;
    }
}