/* footer.css */

footer {
    background: linear-gradient(rgba(28, 111, 150, 0.349) 0%, rgba(255, 255, 255, 1) 100%);
}

.footer {
    width: 100%;
    box-sizing: border-box;
}

.middle-section {
    display: flex;
    margin-left: 100px;
}

.left-section,
.middle-section,
.right-section {
    flex: 1;
}

.left-section {
    width: 100%;
}

.middle-section ul {
    margin-right: 100px;
    color: #333;
    text-decoration: none;
}

.middle-section li {
    margin-top: 25px;
}

.middle-section a:hover {
    text-decoration: underline;
}

.h-links {
    font-size: 20px;
}

.footer-top {
    border-bottom: 1px solid #333;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.footer-button button {
    background-color: #000000;
    color: white;
    border-radius: 30px;
    padding: 5px 15px;
    font-size: 16px;
}

.copyright-footer {
    justify-content: center;
    display: flex;
}

.copyright-footer p {
    font-size: 16px;
}

.h-smaller svg {
    font-size: 25px;
    padding-right: 10px;
}