$primaryTextColor: #000;



/* Filter Overlay */

.randevu-overlay {
    position: fixed;
    top: 0;
    z-index: 9;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Filter Content */

.randevu-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    width: auto;
    height: auto;
}



.psycholog-profile {
    color: $primaryTextColor;
    text-align: none;
    list-style: none;
    padding-top: 50px;
  

    .profile-image-parent {
        display: flex;
        position: relative;

        .online-area {
            // padding: 20px 40px 20px 70px;
            // border-radius: 40px;
            // display: flex;
            // background: #fff;
            // box-shadow: 0px 0px 11px -5px #000;
            // position: absolute;
            // right: -169px;
            // align-items: center;
            // top: 50px;
            // width: 228px;
            // height: 57px;
            // color: #146c94;
            // font-family: Montserrat;
            // font-size: 18px;
            // font-style: normal;
            // font-weight: 500;
            // line-height: normal;
            width: 140px;
            height: 35px;
            background-color: #FFFFFF;
            border-radius: 40px;
            align-items: center;
            justify-content: center;
            display: flex;
            left: 220px;
            position: absolute;
            box-shadow: 0px 0px 11px -5px #000;
            top: 40px;

            .online-circle {
                width: 15px;
                height: 15px;
                flex-shrink: 0;
                background-color: #31f600;
                display: block;
                border-radius: 100%;
                margin-left: 12px;
            }
        }

        .preliminary-interview2 {
            // padding: 20px 20px 20px 70px;
            // border-radius: 40px;
            // display: flex;
            // background: #fff;
            // box-shadow: 0px 0px 11px -5px #000;
            // position: absolute;
            // right: -169px;
            // align-items: center;
            // bottom: 50px;
            // width: 228px;
            // height: 57px;
            // color: #146c94;
            // font-family: Montserrat;
            // font-size: 18px;
            // font-style: normal;
            // font-weight: 500;
            // line-height: normal;
            width: 140px;
            height: 35px;
            background-color: #FFFFFF;
            border-radius: 40px;
            align-items: center;
            justify-content: center;
            display: flex;
            top: 197px;
            right: -8px;
            position: absolute;
            font-size: 14px;
            font-weight: 500;
            color: #146C94;
            box-shadow: 0px 0px 11px -5px #000;
        }
    }

    .profile-area-full {
        width: 341px;
        height: 341px;
        margin-left: 50px;
        position: relative;
    }

    .profile-image {
        flex-shrink: 0;
        border-radius: 100%;
        overflow: hidden;
        position: relative;
        z-index: 2;
        width: 250px;
        height: 250px;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .info {
     

        h1 {
            color: #000;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-decoration: none;
            margin-bottom: 10px;
            margin-top: 0;
        }

        .subtitle {
            color: #000;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 20px;
            display: block;
        }

        .counts {
            span {
                background-color: #F5F5F5;
                border-radius: 40px;
                padding: 5px 15px;
                font-size: 15px;
            }

            span:first-child {
                margin-right: 20px;

            }
        }

        .meeting-info {
            margin-top: 30px;

            span {
                width: 73px;
                height: 29px;
                flex-shrink: 0;
                background: #146c94;
                padding: 6px 17px;
                color: #fff;
            }

            span:first-child {
                border-radius: 10px 0px 0px 0px;
                margin-right: 5px;
            }

            span:last-child {
                border-radius: 0px 0px 10px 0px;
            }
        }

        .stars {
            margin-top: 20px;
            display: flex;

            svg {
                margin-right: 30px;
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                fill: #146c94;
                stroke-width: 10px;
                stroke: #000;
            }

            svg:last-child {
                fill: #fff;
            }
        }

        .buttons {

            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 490px;

            .psycho-right-button {
                margin-right: 37px;
            }
        }
    }

    .education-sertifika {
        background-color: #F5F5F5;
        padding: 20px 20px;
        width: 83%;

        h1 {
            font-size: 20px;
            text-decoration: none;
            font-weight: 800;
            margin: 0;
            margin-bottom: 10px;
        }

        .education-info {
            margin-bottom: 20px;

            span {
                color: #146C94;
                font-weight: 500;
            }

            p {
                color: #146C94;
                font-weight: 700;
            }
        }

        .sertifika-info {
            span {
                color: #146C94;
                font-weight: 500;
            }

            p {
                color: #146C94;
                font-weight: 700;
            }
        }
    }

    .psycho-info {
        margin-top: 50px;

        h1 {
            color: #146c94;
            font-family: Montserrat;
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-decoration: none;

            .t-upper {
                color: #146c94;
                font-family: Montserrat;
                font-size: 25px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                text-decoration: none;
            }

            .last-text {
                color: #000;
                font-family: Montserrat;
                font-size: 15px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
            }
        }

        p {
            color: #000;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .t-upper {
        text-transform: uppercase;
        color: #146c94;
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-decoration: none;
    }

    .expertise {
        margin-top: 50px;

        .list-expertise {
            margin-top: 30px;
        }

        h1 {
            color: #146c94;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-decoration: none;
            margin-bottom: 10px;
            margin-top: 0;
        }

        color: #146c94;
        font-family: Montserrat;
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-decoration: none;

        .t-upper {
            color: #146c94;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-decoration: none;
        }

        .last-text {
            color: #000;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
        }
    }

    .comments {
        padding-bottom: 60px;
        margin-top: 20px;

        h1 {
            color: #146c94;
            font-family: Montserrat;
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-decoration: none;

            .last-text {
                color: #000;
                font-family: Montserrat;
                font-size: 32px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
            }
        }

        .card-comment h1 {
            margin-top: 0px;
        }
    }

    .top-stars {
        display: flex;
        margin-bottom: 40px;
    }

    .sertifika {

        border: 1px solid #E0E0E0;
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .sertifika-head {
            background-color: white;
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            h1 {
                text-decoration: none;
                color: #000;
                font-size: 20px;
                padding: 0;
                margin: 0;
                font-weight: 400;
            }

            .icon {
                font-size: 25px;
                color: gray !important;
            }
        }

        .sertifika-body {
            background-color: #F5F5F5;
            padding: 20px 20px;

            .sertifika-body-info {
                margin-bottom: 20px;

                p {
                    font-weight: 700;
                }
            }

        }
    }

    .education {
        border: 1px solid #E0E0E0;
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .education-head {
            background-color: white;
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            h1 {
                text-decoration: none;
                color: #000;
                font-size: 20px;
                padding: 0;
                margin: 0;
                font-weight: 400;
            }

            .icon {
                font-size: 25px;
                color: gray !important;
            }
        }

        .education-body {
            background-color: #F5F5F5;
            padding: 20px 20px;

            .education-body-info {
                margin-bottom: 20px;

                p {
                    font-weight: 700;
                }
            }

        }
    }
}
.resp-psycholog-profile{
    display: none;
}

.pyschologistProfileIcon svg {
    width: 17px;
    height: 17px;
}
@media only screen and (max-width:1280px){

// .psycholog-profile{
//     display: none;
// }
}