.modal-modal {
    margin: 30px;
}

.card-content-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scrollable-container {
    padding: 20px;
    background-color: #bfcddf65;
    height: 400px;
    /* Adjust the height as needed */
    overflow-y: scroll;
}

.infos-section {
    margin: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card-profil-img {
    margin-bottom: 10px;
}

.infos-section {
    text-align: center;
}

.first-text {
    margin-bottom: 10px;
}

.second-text {
    font-size: 20px;
    margin-bottom: 5px;
}

.Reservation-Card {
    height: auto;
    padding: 40px;
    background: linear-gradient(rgba(28, 111, 150, 0.103) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reserve-input input {
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px 200px;
    margin: 30px;
    padding-left: 30px;
    font-size: 20px;
}

.reserve-input-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reserve-input-second input {
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 5px 180px;
    margin: 5px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
}

.card-section {
    display: flex;
    justify-content: center;
}

.reserve-button button {
    border-radius: 50px;
    background-color: rgb(28, 111, 150);
    width: 200px;
    height: 50px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.reserve-button button:hover {
    background-color: rgba(28, 111, 150, 0.514);
}

.cards-third-section ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reserve-buttonnd {
    border-radius: 50px;
    background-color: rgb(28, 111, 150);
    width: 180px;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    justify-content: center;
    display: flex;
}

.client-seans p {
    background-color: #BFCDDF;
    border-radius: 40px;
    padding: 5px 15px;
    font-size: 15px;
    margin-right: 20px;
}

.client-seans p:last-child {
    margin-right: 0px !important;
}

.appointmentBtn {
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}