.card-star{
    border-radius: 30px;
    background: #DAF1FF;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 15px;
    cursor: pointer;
}

.card-star.active{
    background: transparent;
}

.card-star.active svg{
    width: 30px !important;
    height: 30px !important;
}

.card-star svg.active{
    fill: #146C94;
    stroke-width: 1px;
    stroke: #000;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.card-star svg{
    fill: transparent;
    stroke-width: 10px;
    stroke: #000;
}