.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-control {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea.form-control {
    resize: vertical;
}

button {
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    border-radius: 999px;
}

.file-upload {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 9999px
}

.file-upload .import-icon {
    position: absolute;
    left: 10px;
    font-size: 16px;
    color: #007bff;
}

.file-upload .form-control-file {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.file-upload-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.file-upload-text:hover {
    background-color: #0056b3;
}

.cvUpload {
    text-align: center;
    border: 2px solid #000;
    border-radius: 999px;
    height: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-weight: 500 !important;
}


@media only screen and (max-width:768px) {

    .image-image-contact {
        display: none;
    }

    .form-form-contact {
        width: 100%;
    }

    .contactList {
        margin: 10px;

    }



}