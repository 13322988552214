.tabBtnPrimary:hover {
    text-decoration: underline;
    color: #146C94;
    font-weight: 800;
    font-size: 20px;
    background-color: white;
}

.tabBtnPrimary.active {
    text-decoration: underline;
    color: #146C94;
    font-weight: 800;
    font-size: 16px !important;
    background-color: white;
}

.psychoCardIcon:hover {
    transform: scale(1.2);
}

@media only screen and (max-width:1280px) {
    .tabBtnPrimary.active {
        font-size: 16px;
    }

    .tabBtnPrimary:hover {
        font-size: 16px;
    }
}