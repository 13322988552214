.header {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.left-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.headercenter ul {
    justify-content: center;
}

.headercenter ul li {
    padding-right: 30px;
    display: flex;
    justify-content: center;
}

.active {
    color: #146C94;
    text-decoration: underline;
}

.header-location {
    display: flex;
    justify-content: space-between;
}

.headerright ul {
    margin: 10px;
    display: flex;
    justify-content: center;
}

.headerright ul li {
    padding-right: 30px;
    display: flex;
    justify-content: center;
    color: #146C94;
}

.headerbell {
    display: flex;
    justify-content: center;
    margin: 5px 40px;
}

.bellbell {
    height: 30px;
}

.username-surname li {
    margin-left: 10px;
    line-height: 21px;
}

.card-profil-img-2 {
    background-color: #146C94;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    margin-left: 40px;
}

.left-left-second {
    display: flex;
    align-items: center;
    /* justify-content: start; */
    width: 70%;
}

.header-userpanel-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 40px;
}

.opacity-bg {
    background: gray;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    opacity: .7;
    width: 100%;
    height: 100vh;
}