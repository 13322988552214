.content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card-section-PH {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
}

.reserve-input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reserve-input input::placeholder {
    color: black;
}


@media only screen and (max-width:1180px) {
    .content {
        display: flex;
        flex-direction: column;
    }



}

@media only screen and (max-width:768px) {
    .h p {
        font-size: 20px !important;
    }

    .content {
        display: flex;
        flex-direction: column;
    }

    .datecard-resp {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 10px;
    }

    .reserve-input input {
        width: 250px;
        padding: 10px;
    }

    .input-for-font {
        font-size: 14px !important;
    }


}