h1 {
    font-size: 28px;
    color: #146C94;
    text-decoration: underline;
}

.psycho {
    display: flex;
    margin-bottom: 120px;
}

.mr {
    margin-right: 70px;
}

.lawyer {
    display: flex;
    margin-bottom: 228px;
}

.teacher {
    display: flex;
    ;
}

.cp {
    display: flex !important;
    justify-content: center;
    align-items: center;
}


/* CSS */


/* Stil eklemek istediğiniz diğer bileşenlerin stilleri */


/* Filter Area */

.filter-area {
    display: inline-block;
    padding: 8px 12px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
}

.filter-area span {
    font-size: 16px;
    font-weight: bold;
}


/* Filter Overlay */

.filter-overlay {
    position: fixed;
    top: 0;
    z-index: 9;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Filter Content */

.filter-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    position: relative;
}

.filter-content h2 {
    font-size: 20px;
    margin-bottom: 16px;
}


/* CSS */


/* Filter Area */

.filter-area {
    display: inline-block;
    padding: 8px 12px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
}

.filter-area span {
    font-size: 16px;
    font-weight: bold;
}


/* Filter Overlay */

.filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Filter Content */

.filter-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    width: 400px;
}

.filter-content h2 {
    font-size: 20px;
    margin-bottom: 16px;
}


/* Close Filter Button */

.close-filter-btn {
    position: absolute;
    font-size: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: black;
    top: 0px;
    right: 10px;
}

.others-header-psikolog-expert {
    display: flex;
    justify-content: end;
    align-items: center;
}


/* Input ve Select Stilleri */

.filter-content div {
    margin-bottom: 16px;
}

.filter-content label {
    display: block;
    margin-bottom: 8px;
}

.filter-content input[type="text"],
.filter-content input[type="number"],
.filter-content select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.filter-content select {
    cursor: pointer;
}

.scrollable-container {
    padding: 20px;
    height: 600px;
    /* Adjust the height as needed */
    overflow-y: scroll;
}



@media only screen and (max-width:1280px) {
    .special-resp-psiko {
        display: flex;
    }

    .close-filter-btn {
        font-size: 40px !important;
    }

    .others-header-psikolog-expert {
        display: flex;
        justify-content: end;
        align-items: center;
    }

}

@media only screen and (max-width:727px) {
    .special-media-margin div {
        margin-right: 0px !important;
        margin-bottom: 20px !important;
    }

    .special-media-margin {
        display: flex;
        flex-direction: column;
    }

    .special-resp-psiko {
        display: flex;
        flex-direction: column-reverse;
    }

    .others-header-psikolog-expert {
        flex-direction: column;
    }

    .others-header-psikolog-expert button {
        margin-left: 0px;
    }

    .cp {
        margin-top: 10px;
    }
}