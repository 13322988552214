.faq-item {
    margin-bottom: 10px;
    color: black;
}

.faq-question {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #F3F9F9;
    padding: 15px 20px;
    /* Azaltılmış padding */
    word-wrap: break-word;
    /* Uzun kelimeleri kır */
}

.faq-answer {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #F3F9F9;
    padding: 15px 30px;
    /* Azaltılmış padding */
    max-width: 100%;
    /* Maksimum genişlik belirlendi */
    word-wrap: break-word;
    /* Uzun kelimeleri kır */
}

.faq-question-dot {
    font-size: 12px;
    margin-right: 5px;
}

.faq-question.active {
    font-weight: bold;
}

.faq-question.active {
    text-decoration: none;
}

.faqQuestion,
.faq-answer {
    font-size: .875rem;
    font-weight: 600;
}